import { ILease } from "../interfaces/Billing/ILease";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export let LeaseModel: ILease = {
  id: 0,
  label: null,
  codeCRO: null,
  status: 1,
  propertyId: 0,
  propertyArea: null,
  propertyAddress: null,
  propertyAddressComplement: null,
  propertyZipCode: null,
  propertyCity: null,
  propertyCountry: "FRANCE",
  propertyTaxProvisioned: 0,
  propertyLatitude: 0,
  propertyLongitude: 0,
  tenantFirstName: null,
  tenantLastName: null,
  landlordName: null,
  landlordAddress: null,
  landlordAddressComplement: null,
  landlordZipCode: null,
  landlordCity: null,
  landlordCountry: null,
  landlordContactId: 0,
  landlordContactCivility: 0,
  landlordContactFirstName: null,
  landlordContactLastName: null,
  landlordContactEmail: null,
  landlordContactPhone: null,
  tenantId: 0,
  tenantName: null,
  tenantAddress: null,
  tenantAddressComplement: null,
  tenantZipCode: null,
  tenantCity: null,
  tenantCountry: "FRANCE",
  tenantContactId: 0,
  tenantContactCivility: 0,
  tenantContactFirstName: null,
  tenantContactLastName: null,
  tenantContactEmail: null,
  tenantContactPhone: null,
  startDate: new Date().toISOString(),
  endDate: new Date(dayjs().add(9, "year")).toISOString(),
  duration: 9,
  durationUnit: 0,
  paymentDay: null,
  rentHcHt: 0,
  tvaRentId: null,
  isChargesPackage: true,
  chargeHt: 0,
  tvaChargeId: null,
  securityDeposit: 0,
  tenantIsParticular: false,
  tenantCivility: 0,
  isRentReview: true,
  isSubscriptReferenceRentReview: true,
  rentReviewSubScriptType: null,
  rentReviewSubScript: null,
  rentReviewPercentage: 0,
  rentReviewPeriodicityId: null,
  isSecurityDepositReview: true,
  securityDepositReviewPeriodicityId: 1,
  startDateFirstReceipt: new Date().toISOString(),
  subscriptFirstReceipt: null,
  endDateFirstQuittance: new Date(dayjs().endOf("month")).toISOString(),
  periodicityId: 1,
  propertyTypeId: null,
  leaseTypeId: null,
  affairId: null,
  institutionId: null,
  tvaSecurityDepositId: null,
  tvaPropertyTaxId: null,
  tacitAgreement: false,
  prorataShare: null,
};
