<template lang="pug">
div#templateColsContainer.px-1.pt-1
  .justify-content-center.h-100(v-if='isLoadingLease || isCreatingLease || isUpdatingLease')
    .content-loader-center.m-0.h-100
      .text-center.flex-center
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          |  {{ isCreatingLease || isUpdatingLease ? 'Bail en cours d\'enregistrement' : leaseForm.id > 0 ? 'Chargement des détails du bail...' : 'Chargement du formulaire...'}}
  b-row.content-scrollable-sticky.m-0.justify-content-center(v-else style="height: calc(100% - 110px)")
    div.col-9.content-scrollable-sticky-main.cell-right.h-100
      .px-1.h-100
        validation-observer(ref='formCreateLease' #default='{ errors }')
          b-form.h-100(autocomplete='off')
            b-row.my-0.pb-2
              b-col.content-header.d-flex.align-items-center.justify-content-between
                .d-flex.align-items-center
                  h2.content-header-title.float-left.m-0.pr-1.mr-1(style="border-right: 1px solid #e2e2e2")
                    | {{ $route.meta.pageTitle }}
                  .content-header-search.mr-1
                    h3.m-0 {{leaseForm.label}}
                .content-header-actions.d-flex
                  feather-icon.cursor-pointer(icon='XIcon' size='24' @click='cancel()')
            .d-flex.flex-column.h-100(style="overflow-x: hidden")
              #property.pt-0
                b-row.my-0
                  b-col.py-0.mb-1(md="12" lg="3" xl="2")
                    b-form-group(label='Code *' label-for='codeCRO' style='flex:1')
                      validation-provider(#default='{ errors }' name='codeCRO' rules="required")
                        ejs-textbox(id="codeCRO" v-model="leaseForm.codeCRO" name="codeCRO")
                        small.text-danger {{ errors[0] }}
                  b-col.py-0.mb-1(md="12" lg="6" xl="8")
                    b-form-group(label='Nom de dossier *' label-for='label')
                      validation-provider(#default='{ errors }' name='Nom' rules="required")
                        ejs-textbox(id="affairName" v-model="leaseForm.label" name="Nom")
                        small.text-danger {{ errors[0] }}
                  b-col.py-0.mb-1(md="12" lg="3" xl="2")
                    b-form-group(label='Statut' label-for='affairStatus')
                      validation-provider(#default='{ errors }' name='affairStatus')
                        ejs-dropdownlist(id='affairStatus' popupHeight='300px' filterType='Contains' :allowFiltering='true' :dataSource='statusList' :fields="{ text: 'label', value: 'value' }" v-model='leaseForm.status')
                b-row.my-0
                  b-col.py-0.mb-1(cols='12')
                    h3 Adresse du bien
                    b-form-group
                      validation-provider(#default='{ errors }' name='propertyAddress')
                        vue-typeahead-bootstrap#propertyAddress.not-autocomplete(style="cursor: default;" type='text' :class="errors.length > 0 ? 'is-invalid' : ''" :maxMatches="100" :showAllResults="true" :minMatchingChars="2" :state='errors.length > 0 ? false : null' name="propertyAddress" v-model='leaseForm.propertyAddress' :data='autocompletePropertyData' :serializer='item => item.properties.label'  placeholder='Rechercher une adresse ou saisissez : Rue et n° de rue' @input='autocompleteAddressSource()' @hit='autocompleteAddressSelected' autocomplete='nope' aria-autocomplete='nope')
                          template(#prepend)
                            b-input-group-text.px-50.py-0.bg-gray
                              span.material-icons-outlined.text-primary place
                          template(slot='suggestion' slot-scope='{ data, htmlText }')
                            .item
                              small {{data.properties.name}}
                              span {{data.properties.postcode+ ' ' + data.properties.city}}
                  b-col.py-0.mb-1(cols='12')
                    validation-provider(#default='{ errors }' name='propertyAddressComplement')
                      ejs-textbox(id="propertyAddressComplement" placeholder="Batiment, Appartement.." v-model="leaseForm.propertyAddressComplement" name="propertyAddressComplement")
                b-row.my-0
                  b-col.py-0.mb-1(cols='3')
                    validation-provider(#default='{ errors }' name='propertyZipCode')
                      ejs-textbox(id="propertyZipCode" placeholder="Code postal" v-model="leaseForm.propertyZipCode" name="propertyZipCode")
                  b-col.py-0.mb-1(cols='9')
                    validation-provider(#default='{ errors }' name='propertyCity')
                      ejs-textbox(id="propertyCity" placeholder="Ville" v-model="leaseForm.propertyCity" name="propertyCity")
                b-row.my-0
                  b-col.py-0.mb-50(cols='12')
                    validation-provider(#default='{ errors }' name='propertyCountry')
                      ejs-textbox(id="propertyCountry" placeholder="Pays" v-model="leaseForm.propertyCountry" name="propertyCountry")
                  b-col.py-0.mb-50(cols='12' :class="displayGps ? 'mb-50' : 'mb-1'")
                    small.cursor-pointer.text-primary(@click="displayGps = !displayGps") {{ displayGps ? '- Masquer les coordonnées GPS' : '+ Editer les coordonnées GPS' }} 
                b-row.my-0(v-if="displayGps")
                  b-col.py-0(cols='12')
                    label(for="address'") Coordonnées GPS
                  b-col.py-0.mb-1(cols='6')
                    validation-provider(name='latitude')
                      ejs-textbox(id="latitude" placeholder="Latitude" v-model="leaseForm.propertyLatitude" name="propertyLatitude")
                  b-col.py-0.mb-1(cols='6')
                    validation-provider(name='longitude')
                      ejs-textbox(id="longitude" placeholder="Longitude" v-model="leaseForm.propertyLongitude" name="propertyLongitude")
                b-row.my-0
                  b-col.py-0(cols='12')
                    label(label-for='address' style='font-weight: 600;') Description du bien
                  b-col.py-0.mb-1(cols='6')
                    b-form-group
                      label(label-for='propertyType') Type de bien *
                      validation-provider(#default='{ errors }' name='propertyType' rules="required")
                        ejs-dropdownlist(id='propertyType' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource='propertyTypeList' :fields="{ text: 'label', value: 'id' }" v-model='leaseForm.propertyTypeId')
                          template(v-slot:footerTemplate)
                            div.p-50
                              ejs-button(v-on:click.native="newPropertyType" :content="'+ Nouveau type de bien'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
                        small.text-danger {{ errors[0] }}
                  b-col.py-0.mb-1(cols='3')
                    validation-provider(name='propertyArea')
                      label(label-for='propertyArea')
                        | Surface
                      ejs-numerictextbox(id="propertyArea" format="########.## m²" v-model="leaseForm.propertyArea")
                  b-col.py-0.mb-1(cols='3')
                    validation-provider(name='prorataShare')
                      label(label-for='prorataShare')
                        | Quote-part
                      ejs-numerictextbox(id="prorataShare" minimumFractionDigits="3" maximumFractionDigits="3" :step="0.001" format='n3' v-model="leaseForm.prorataShare")
                vs-divider.mt-0.mb-1(color='#cccccc')
              #contact.pt-1
                b-row.my-0
                  b-col.py-0(cols='12' :class="differentOwnerAddress ? 'mb-1' : ''")
                    h3 Bailleur / Propriétaire du bien
                    b-form-group.mb-1
                      label(label-for='searchLandlord' style='font-weight: 600;') Société / Particulier
                      validation-provider(default='{ errors }' name='searchLandlord')
                        vue-typeahead-bootstrap#searchLandlord.not-autocomplete(type='text' disabled inputClass='bg-light-primary' :class="errors.length > 0 ? 'is-invalid' : ''" :maxMatches="100" :minMatchingChars="3" v-model='leaseForm.landlordName' :data='autocompleteLandlordData' :serializer="item => item.name" :screen-reader-text-serializer="item => item.name" placeholder='Rechercher par nom ou code analytique' autocomplete='nope' aria-autocomplete='nope' style="cursor: not-allowed;")
                          template(#prepend)
                            b-input-group-text.px-50.py-0.bg-gray
                              span.material-icons-outlined.text-primary business
                          template(slot='suggestion' slot-scope='{ data, htmlText }')
                            .item
                              div
                                span {{data.name}}
                                img.rounded(:src="require('@/assets/images/logo/logo-'+data.source+'.png')" style="height:20px; float:right; right:10px; border-radius:5px; padding:0px 5px" v-if="data.source && data.source!=='orisis'")
                                span.rounded.bg-transparent.small(v-else :class="data.id>0 ? 'border-primary bg-primary text-white' : 'border-primary bg-white text-primary' " style="float:right; right:10px; padding:0px 5px") {{data.id>0 ? "Orisis" : data.source}}
                              small(v-if="data.addresses && data.addresses.length>0")
                                | {{data.addresses[0].address}} - {{data.addresses[0].zipCode+ ' ' + data.addresses[0].city}}
                div
                  b-row.my-0
                    b-col.py-0.mb-1(cols='12')
                      label(label-for='address' style='font-weight: 600;') Adresse
                      b-form-group
                        validation-provider(#default='{ errors }' name='landlordAddress')
                          ejs-textbox(id="landlordAddress" disabled placeholder="Rue et n° de rue" v-model="leaseForm.landlordAddress" name="landlordAddress")
                    b-col.py-0.mb-1(cols='12')
                      validation-provider(#default='{ errors }' name='landlordAddressComplement')
                        ejs-textbox(id="landlordAddressComplement" disabled placeholder="Batiment, Appartement.." v-model="leaseForm.landlordAddressComplement" name="landlordAddressComplement")
                  b-row.my-0
                    b-col.py-0.mb-1(cols='3')
                      validation-provider(#default='{ errors }' name='landlordZipCode')
                        ejs-textbox(id="landlordZipCode" disabled placeholder="Code postal" v-model="leaseForm.landlordZipCode" name="landlordZipCode")
                    b-col.py-0.mb-1(cols='9')
                      validation-provider(#default='{ errors }' name='landlordCity')
                        ejs-textbox(id="landlordCity" disabled placeholder="Ville" v-model="leaseForm.landlordCity" name="landlordCity")
                  b-row.my-0
                    b-col.py-0.mb-1(cols='12')
                      validation-provider(#default='{ errors }' name='landlordCountry')
                        ejs-textbox(id="landlordCountry" disabled placeholder="Pays" v-model="leaseForm.landlordCountry" name="landlordCountry")
                b-row.my-0
                  b-col.py-0.mb-1(cols='12')
                    label(label-for='searchLandlordContact' style='font-weight: 600;') Contact principal
                    b-form-group
                      validation-provider(#default='{ errors }' name='searchLandlordContact')
                        vue-typeahead-bootstrap#searchLandlordContact.not-autocomplete(type='text' :class="errors.length > 0 ? 'is-invalid' : ''" :maxMatches="100" :minMatchingChars="3" :state='errors.length > 0 ? false : null' inputClass='bg-light-primary' v-model='searchLandlordContact' :data='autocompleteLandlordContactData' :serializer="item => item.firstName + ' ' + item.lastName" placeholder='Rechercher par nom ou code analytique' @input='autocompleteLandlordContact()' @hit='autocompleteLandlordContactSelected' autocomplete='nope' aria-autocomplete='nope')
                          template(#prepend)
                            b-input-group-text.px-50.py-0.bg-gray
                              span.material-icons-outlined.text-primary person
                          template(slot='suggestion' slot-scope='{ data, htmlText }')
                            .item
                              div
                                span {{ data.id > 0 ? data.displayLabel : data.firstName + ' ' + data.lastName}}
                                span.rounded.bg-transparent.small(:class=" data.id>0 ? 'border-primary bg-primary text-white' : 'border-primary bg-white text-primary'" style="float:right; right:10px; padding:0px 5px") {{data.id>0 ? "Orisis" : data.source}}
                              small
                                | {{ data.email ? data.email : ''}} {{ data.email && data.phoneNumber ? ' / '+ data.phoneNumber :  data.phoneNumber ? data.phoneNumber : '' }}
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      validation-provider(#default='{ errors }' name='landlordContactCivility')
                        ejs-dropdownlist(id='landlordContactCivility' placeholder="Civilité" popupHeight='300px' :allowFiltering='true' :dataSource='optionCivility' :fields="{ text: 'label', value: 'value' }" v-model='leaseForm.landlordContactCivility')
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      validation-provider(#default='{ errors }' name='landlordContactLastName')
                        ejs-textbox(id="landlordContactLastName" placeholder="Nom" v-model="leaseForm.landlordContactLastName" name="landlordContactLastName")
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      validation-provider(#default='{ errors }' name='landlordContactFirstName')
                        ejs-textbox(id="landlordContactFirstName" placeholder="Prénom" v-model="leaseForm.landlordContactFirstName" name="landlordContactFirstName")
                  b-col.py-0.mb-1(cols='6')
                    b-form-group(label-for='landlordContactPhone')
                      ejs-textbox(id="landlordContactPhone" placeholder="Téléphone" v-model="leaseForm.landlordContactPhone" name="landlordContactPhone")
                  b-col.py-0.mb-1(cols='6')
                    b-form-group(label-for='landlordContactEmail')
                      validation-provider(#default='{ errors }' name='landlordContactEmail' rules='email')
                        ejs-textbox(id="landlordContactEmail" placeholder="Adresse e-mail" v-model="leaseForm.landlordContactEmail" name="landlordContactEmail")
                        small.text-danger {{ errors[0] }}
                vs-divider.mt-0.mb-1(color='#cccccc')
                b-row.my-0
                  b-col.py-0.mb-1(cols='12')
                    h3 Locataire du bien
                    b-form-group.mb-1
                      ejs-checkbox(label='Particulier ?' v-model="leaseForm.tenantIsParticular" @change="resetTenant")
                    b-form-group
                      label(label-for='tenantName' style='font-weight: 600;') {{ leaseForm.tenantIsParticular ? 'Particulier *' : 'Société *' }}
                      validation-provider(#default='{ errors }' name='tenantName' :rules="!leaseForm.tenantIsParticular ? 'required' :(!leaseForm.tenantCivility || !leaseForm.tenantLastName ? 'required' : '')")
                        vue-typeahead-bootstrap#tenantName.not-autocomplete(type='text' inputClass='bg-light-primary' :maxMatches="100" :minMatchingChars="3" v-model='leaseForm.tenantName' :data='autocompleteTenantData' :serializer="item => item.companyAccount? item.name+' / '+item.companyAccount : item.name" :screen-reader-text-serializer="item => item.name" placeholder='Rechercher par nom ou code analytique' @input='autocompleteTenant()' @hit='autocompleteTenantSelected' autocomplete='nope' aria-autocomplete='nope' :style="leaseForm.id ? 'cursor:pointer' : 'cursor: not-allowed;'")
                          template(#prepend)
                            b-input-group-text.px-50.py-0.bg-gray
                              span.material-icons-outlined.text-primary {{ leaseForm.tenantIsParticular ? 'person' : 'business'}}
                          template(slot='suggestion' slot-scope='{ data, htmlText }')
                            .item
                              div
                                span {{data.name}}
                                img.rounded(:src="require('@/assets/images/logo/logo-'+data.source+'.png')" style="height:20px; float:right; right:10px; border-radius:5px; padding:0px 5px" v-if="data.source && data.source!=='orisis'")
                                span.rounded.bg-transparent.small(v-else :class="data.id>0 ? 'border-primary bg-primary text-white' : 'border-primary bg-white text-primary' " style="float:right; right:10px; padding:0px 5px") {{data.id>0 ? "Orisis" : data.source}}
                              small(v-if="data.addresses && data.addresses.length>0")
                                | {{data.addresses[0].address}} - {{data.addresses[0].zipCode+ ' ' + data.addresses[0].city}}
                        small.text-danger(v-if="errors[0]") {{ errors[0] + (leaseForm.isParticular ? " ou renseignez un nouveau locataire" : '') }}
                    b-row.my-0(v-if="leaseForm.tenantIsParticular")
                      b-col.py-0.mt-1(cols='4')
                        b-form-group
                          label(label-for='tenantCivility' style='font-weight: 600;') Civilité *
                          validation-provider(#default='{ errors }' name='tenantCivility' rules="required")
                            ejs-dropdownlist(id='tenantCivility' placeholder="Civilité" popupHeight='300px' :allowFiltering='true' :dataSource='optionCivility' :fields="{ text: 'label', value: 'value' }" v-model='leaseForm.tenantCivility' @input="leaseForm.tenantIsParticular ? leaseForm.tenantName = civilityTranslate(leaseForm.tenantCivility) + (leaseForm.tenantFirstName ? (leaseForm.tenantFirstName + ' ') : '') + (leaseForm.tenantLastName ? leaseForm.tenantLastName : '') : ''")
                            small.text-danger {{ errors[0] }}
                      b-col.py-0.mt-1(cols='4')
                        b-form-group
                          label(label-for='tenantFirstName' style='font-weight: 600;') Prénom
                          validation-provider(#default='{ errors }' name='tenantFirstName')
                            ejs-textbox(id="tenantFirstName" placeholder="Prénom" v-model="leaseForm.tenantFirstName" name="tenantFirstName" @input="leaseForm.tenantIsParticular ? leaseForm.tenantName = civilityTranslate(leaseForm.tenantCivility) + (leaseForm.tenantFirstName ? (leaseForm.tenantFirstName + ' ') : '') + (leaseForm.tenantLastName ? leaseForm.tenantLastName : '') : ''")
                      b-col.py-0.mt-1(cols='4')
                        b-form-group
                          label(label-for='tenantLastName' style='font-weight: 600;') Nom *
                          validation-provider(#default='{ errors }' name='tenantLastName' rules="required")
                            ejs-textbox(id="tenantLastName" placeholder="Nom" v-model="leaseForm.tenantLastName" name="tenantFirstName" @input="leaseForm.tenantIsParticular ? leaseForm.tenantName = civilityTranslate(leaseForm.tenantCivility) + (leaseForm.tenantFirstName ? (leaseForm.tenantFirstName + ' ') : '') + (leaseForm.tenantLastName ? leaseForm.tenantLastName : '') : ''")
                            small.text-danger {{ errors[0] }}
                b-row.my-0(v-if="!leaseForm.tenantIsParticular")
                  b-col.py-0.mb-1(cols='12')
                    label(label-for='searchTenantContact' style='font-weight: 600;') Contact principal 
                    b-form-group
                      validation-provider(#default='{ errors }' name='searchTenantContact')
                        vue-typeahead-bootstrap#searchTenantContact.not-autocomplete(type='text' :class="errors.length > 0 ? 'is-invalid' : ''" :maxMatches="100" :minMatchingChars="3" :state='errors.length > 0 ? false : null' inputClass='bg-light-primary' v-model='searchTenantContact' :data='autocompleteTenantContactData' :serializer="item => item.firstName + ' ' + item.lastName" placeholder='Rechercher par nom ou code analytique' @input='autocompleteTenantContact()' @hit='autocompleteTenantContactSelected' autocomplete='nope' aria-autocomplete='nope')
                          template(#prepend)
                            b-input-group-text.px-50.py-0.bg-gray
                              span.material-icons-outlined.text-primary person
                          template(slot='suggestion' slot-scope='{ data, htmlText }')
                            .item
                              div
                                span {{data.id > 0 ? data.displayLabel : data.firstName + ' ' + data.lastName}}
                                span.rounded.bg-transparent.small( :class="data.id>0 ? 'border-primary bg-primary text-white' : 'border-primary bg-white text-primary'" style="float:right; right:10px; padding:0px 5px") {{data.id>0 ? "Orisis" : data.source}}
                              small
                                | {{ data.email ? data.email : ''}} {{ data.email && data.phoneNumber ? ' / '+ data.phoneNumber :  data.phoneNumber ? data.phoneNumber : '' }}
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      validation-provider(#default='{ errors }' name='tenantContactCivility')
                        ejs-dropdownlist(id='tenantContactCivility' placeholder="Civilité" popupHeight='300px' :allowFiltering='true' :dataSource='optionCivility' :fields="{ text: 'label', value: 'value' }" v-model='leaseForm.tenantContactCivility')
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      validation-provider(#default='{ errors }' name='tenantContactLastName')
                        ejs-textbox(id="tenantContactLastName" placeholder="Nom" v-model="leaseForm.tenantContactLastName" name="tenantContactLastName")
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      validation-provider(#default='{ errors }' name='tenantContactFirstName')
                        ejs-textbox(id="tenantContactFirstName" placeholder="Prénom" v-model="leaseForm.tenantContactFirstName" name="tenantContactFirstName")
                  b-col.py-0.mb-1(cols='6')
                    b-form-group(label-for='tenantContactPhone')
                      ejs-textbox(id="tenantContactPhone" placeholder="Téléphone" v-model="leaseForm.tenantContactPhone" name="tenantContactPhone")
                  b-col.py-0.mb-1(cols='6')
                    b-form-group(label-for='tenantContactEmail')
                      validation-provider(#default='{ errors }' name='tenantContactEmail' rules='email')
                        ejs-textbox(id="tenantContactEmail" placeholder="Adresse e-mail" v-model="leaseForm.tenantContactEmail" name="tenantContactEmail")
                        small.text-danger {{ errors[0] }}
                vs-divider.mt-1.mb-1(color='#cccccc')
              #legal.pt-1
              b-row.my-0
                b-col.py-0.mb-1(cols='12')
                  h3 A propos du bail
                  b-form-group
                    label(label-for='leaseType' style='font-weight: 600;') Type de bail *
                    validation-provider(#default='{ errors }' name='leaseType' rules="required")
                      ejs-dropdownlist(id='leaseType' :footerTemplate="'footerTemplate'" popupHeight='300px' :allowFiltering='true' :dataSource='leaseTypeList' :fields="{ text: 'label', value: 'id' }" v-model='leaseForm.leaseTypeId')
                        template(v-slot:footerTemplate)
                          div.p-50
                            ejs-button(v-on:click.native="newLeaseType" :content="'+ Nouveau type de bail'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
                      small.text-danger {{ errors[0] }}
              b-row.my-0
                b-col.py-0.mb-1(cols='6')
                  b-form-group
                    label(label-for='startDate' style='font-weight: 600;') Début du bail *
                    validation-provider(#default='{ errors }' name='startDate')
                      ejs-datepicker(:format="'dd/MM/yyyy'" locale='fr' v-model="leaseForm.startDate" placeholder="JJ/MM/AAAA" @blur="leaseGroupFunctions({lease: leaseForm})")
                      small.text-danger {{ errors[0] }}
                b-col.py-0.mb-1(cols='6')
                  b-form-group
                    label(label-for='endDate' style='font-weight: 600;') Fin du bail *
                    validation-provider(#default='{ errors }' name='endDate')
                      ejs-datepicker(:format="'dd/MM/yyyy'" locale='fr' v-model="leaseForm.endDate" placeholder="JJ/MM/AAAA" @blur="leaseGroupFunctions({lease: leaseForm})")
                      small.text-danger {{ errors[0] }}
              b-row.my-0
                b-col.py-0.mb-1(cols='6')
                  b-row.my-0
                    b-col.py-0.mb-1(cols='9')
                      b-form-group
                        label(label-for='periodicity' style='font-weight: 600;') Périodicité *
                        validation-provider(#default='{ errors }' name='periodicity' rules="required")
                          ejs-dropdownlist(id='periodicity' :footerTemplate="'footerTemplate'" popupHeight='300px' :allowFiltering='true' :dataSource='periodicitiesList' :fields="{ text: 'label', value: 'id' }" v-model='leaseForm.periodicityId' @select="leaseGroupFunctionsLocal")
                            template(v-slot:footerTemplate)
                              div.p-50
                                ejs-button(v-on:click.native="newPeriodicity" :content="'+ Nouvelle périodicité'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
                          small.text-danger {{ errors[0] }}
                    b-col.py-0.mb-1(cols='3')
                      b-form-group
                        label(label-for='paymentDay' style='font-weight: 600;') Jour paiement
                        validation-provider(#default='{ errors }' name='paymentDay')
                          ejs-dropdownlist(id='paymentDay' popupHeight='300px' :allowFiltering='true' :dataSource='paymentDayList' v-model='leaseForm.paymentDay')
                b-col.py-0.mb-1.d-flex.align-items-center(cols='6')
                  b-row.my-0
                    b-col.py-0.mb-1(cols='12')
                      b-form-checkbox.checkbox-options.mt-1(v-model="leaseForm.tacitAgreement")
                        | Tacite reconduction
              b-row.my-0
                b-col.py-0.mb-1(cols='6')
                  h3 Loyer et Charges annuelles
                  b-row.my-0
                    b-col.py-0.mb-1(cols='9')
                      b-form-group
                        validation-provider(#default='{ errors }' name='rentHcHt' rules="required")
                          label(label-for='rentHcHt' style='font-weight: 600;' ) Loyer hors charges hors taxes *
                          ejs-numerictextbox(id="rentHcHt" format="########.## €" v-model="leaseForm.rentHcHt" @blur="leaseGroupFunctions({lease: leaseForm})")
                          small.text-danger {{ errors[0] }}
                    b-col.py-0.mb-1(cols='3')
                      b-form-group
                        label(label-for='tvaRentId' style='font-weight: 600;') TVA *
                        validation-provider(#default='{ errors }' name='tvaRentId' rules="required")
                          ejs-dropdownlist(id='tvaRentId' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource=`institutionSelected.isSubjectTVA ? referencielTvaOptions : [{label:"0 %", id:5}]` v-model='leaseForm.tvaRentId' @select="leaseGroupFunctions({lease: leaseForm})")
                          small.text-danger {{ errors[0] }}
                  b-row.my-0
                    b-col.py-0.mb-1(cols='12')
                      b-form-group
                        label(label-for='chargesPackage' style='font-weight: 600;') Charges
                        b-form-radio-group#chargesPackage(v-model='leaseForm.isChargesPackage' name='chargesPackage' :options='chargesPackageOptions' text-field='label')
                  b-row.my-0
                    b-col.py-0.mb-1(cols='9')
                      b-form-group
                        validation-provider(#default='{ errors }' name='chargeHt')
                          label(label-for='chargeHt' style='font-weight: 600;') Montant des charges
                          ejs-numerictextbox(id="chargeHt" format="########.## €" v-model="leaseForm.chargeHt" @blur="leaseGroupFunctions({lease: leaseForm})")
                    b-col.py-0.mb-1(cols='3')
                      b-form-group
                        label(label-for='tvaChargeId' style='font-weight: 600;') {{ leaseForm.chargeHt ? 'TVA *' : 'TVA' }}
                        validation-provider(#default='{ errors }' name='tvaChargeId' :rules="leaseForm.chargeHt ? 'required' : ''")
                          ejs-dropdownlist(id='tvaChargeId' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource=`institutionSelected.isSubjectTVA ? referencielTvaOptions : [{label:"0 %", id:5}]` v-model='leaseForm.tvaChargeId' @select="leaseGroupFunctions({lease: leaseForm})")
                          small.text-danger {{ errors[0] }}
                    b-col.py-0.mb-1(cols='9')
                      b-form-group
                        validation-provider(#default='{ errors }' name='propertyTaxProvisioned')
                          label(label-for='propertyTaxProvisioned' style='font-weight: 600;') Taxe foncière de référence
                          ejs-numerictextbox(id="propertyTaxProvisioned" format="########.## €" v-model="leaseForm.propertyTaxProvisioned" @blur="leaseGroupFunctions({lease: leaseForm})")
                    b-col.py-0.mb-1(cols='3')
                      b-form-group
                        label(label-for='tvaPropertyTaxId' style='font-weight: 600;') {{ leaseForm.propertyTaxProvisioned ? 'TVA *' : 'TVA' }}
                        validation-provider(#default='{ errors }' name='tvaPropertyTaxId' :rules="leaseForm.propertyTaxProvisioned ? 'required' : ''")
                          ejs-dropdownlist(id='tvaPropertyTaxId' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource=`institutionSelected.isSubjectTVA ? referencielTvaOptions : [{label:"0 %", id:5}]` v-model='leaseForm.tvaPropertyTaxId' @select="leaseGroupFunctions({lease: leaseForm})")
                          small.text-danger {{ errors[0] }}
                b-col.py-0.mb-1(cols='6')
                  .bg-light-primary.p-1.rounded
                    h4 Simulation des totaux d'une quittance
                    div(v-if='sumLeaseModel && sumLeaseModel.totalDocument && sumLeaseModel.documentDetails')
                      #simulation-loyer.d-flex.w-100.justify-content-between(v-for="(documentDetail, key) in sumLeaseModel.documentDetails")
                        .text-left.flex(v-html="documentDetail.description" style='width: 80%;')
                        .text-right(style='width: 20%;') {{ formatCurrency(documentDetail.unitPriceHt) }}
                      #simulation-charge.d-flex.w-100.justify-content-between.mt-2.border-bottom-secondary
                        .text-left.flex(style='width: 80%;') Total HT
                        .text-right(style='width: 20%;')  {{ formatCurrency(sumLeaseModel.totalDocument.totalNetHt) }}
                      #simulation-charge.d-flex.w-100.justify-content-between.border-bottom.border-grey(v-for="(item, index) in sumLeaseModel.totalDocument.tvaDetails")
                        .text-left.flex(style='width: 80%;') TVA {{ referencielTvaTranslate(item.referencielTvaId) }}
                        .text-right(style='width: 20%;') {{ formatCurrency(item.priceTva.toFixed(2)) }}
                      #simulation-charge.d-flex.w-100.justify-content-between.border-bottom.border-grey(v-if='sumLeaseModel.totalDocument.tvaDetails.length > 1')
                        .text-left.flex(style='width: 80%;') Total TVA
                        .text-right(style='width: 20%;') {{ formatCurrency(sumLeaseModel.totalDocument.totalTva.toFixed(2)) }}
                      #simulation-charge.d-flex.w-100.justify-content-between.border-bottom.border-top-secondary.border-bottom-secondary
                        .text-left.flex.font-weight-bold(style='width: 80%;') Total TTC
                        .text-right.font-weight-bold(style='width: 20%;') {{ formatCurrency(sumLeaseModel.totalDocument.totalTtc) }}
                    div(v-else)
                      .p-2.text-center
                        | Veuillez indiquer tous les paramètres financier de votre bail pour obtenir une simulation de facture
              vs-divider.mt-1.mb-1(color='#cccccc')
              b-row.my-0
                b-col.py-0.mb-1(cols='12')
                  h4 Révision du loyer
              b-row.my-0
                b-col.py-0.mb-1(cols='12')
                  b-form-group
                    b-form-checkbox.mr-0.mt-50.font-weight-bold(v-model='leaseForm.isRentReview' name='is-rtl' switch='' inline='') Activer la révision du loyer
                  label
                    | En activant cette option, le montant du loyer sera révisé automatiquement à chaque période de révision.
              div(v-if='leaseForm.isRentReview')
                b-row.my-0
                  b-col.py-0.mb-1(cols='12')
                    b-form-group
                      label(label-for='subscriptReferenceRentReview' style='font-weight: 600;') Type de révision
                      b-form-radio-group#subscriptReferenceRentReview(v-model='leaseForm.isSubscriptReferenceRentReview' name='subscriptReferenceRentReview' :options='subscriptReferenceRentReviewOptions' text-field='label')
                b-row.my-0(v-show='leaseForm.isSubscriptReferenceRentReview')
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      label(label-for='rentReviewSubScriptType' style='font-weight: 600;') Type d'indice
                      validation-provider(#default='{ errors }' name='rentReviewSubScriptType')
                        ejs-dropdownlist(id='rentReviewSubScriptType' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'value' }" :dataSource='reviewSubScriptTypeList' v-model='leaseForm.rentReviewSubScriptType')
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      label(label-for='rentReviewSubScript' style='font-weight: 600;') Indice
                      validation-provider(#default='{ errors }' name='rentReviewSubScript')
                        ejs-dropdownlist(id='rentReviewSubScript' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'value' }" :dataSource='rentReviewSubScriptList' v-model='leaseForm.rentReviewSubScript')
                b-row.my-0(v-show="!leaseForm.isSubscriptReferenceRentReview")
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      label(label-for='rentReviewPercentage' style='font-weight: 600;') Pourcentage de révision
                      validation-provider(#default='{ errors }' name='rentReviewPercentage')
                        ejs-numerictextbox(id="rentReviewPercentage" floatLabelType="Auto"  :step="step" :min="min" :max="max" format="###.## %" v-model="leaseForm.rentReviewPercentage")
                b-row.my-0
                  b-col.py-0.mb-1(cols='4')
                    b-form-group
                      label(label-for='rentReviewPeriodicity' style='font-weight: 600;') Périodicité
                      validation-provider(#default='{ errors }' name='rentReviewPeriodicity')
                        ejs-dropdownlist(id='rentReviewPeriodicity' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource='periodicitiesList' v-model='leaseForm.rentReviewPeriodicityId')
                          template(v-slot:footerTemplate)
                            div.p-50
                              ejs-button(v-on:click.native="newPeriodicity" :content="'+ Nouvelle périodicité'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
              vs-divider.mt-1.mb-1(color='#cccccc')
              b-row.my-0
                b-col.py-0.mb-1(cols='12')
                  h4 Dépôt de garantie
              b-row.my-0
                b-col.py-0.mb-1(cols='4')
                  b-form-group
                    validation-provider(#default='{ errors }' name='securityDeposit')
                      label(label-for='securityDeposit' style='font-weight: 600;') Dépôt de garantie
                      ejs-numerictextbox(id="securityDeposit" format="########.## €" v-model="leaseForm.securityDeposit")
                b-col.py-0.mb-1(cols='2')
                  b-form-group
                    label(label-for='tvaSecurityDepositId' style='font-weight: 600;') {{ leaseForm.securityDeposit ? 'TVA *' : 'TVA' }}
                    validation-provider(#default='{ errors }' name='tvaSecurityDepositId' :rules="leaseForm.securityDeposit ? 'required' : ''")
                      ejs-dropdownlist(id='tvaSecurityDepositId' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource=`institutionSelected.isSubjectTVA ? referencielTvaOptions : [{label:"0 %", id:5}]` v-model='leaseForm.tvaSecurityDepositId')
                      small.text-danger {{ errors[0] }}
              b-row.my-0
                b-col.py-0.mb-1(cols='12')
                  b-form-group
                    b-form-checkbox.mr-0.mt-50.font-weight-bold(v-model='leaseForm.isSecurityDepositReview' name='is-rtl' switch='' inline='') Activer la révision du dép&ocirc;t de garantie
              b-row.my-0(v-if='leaseForm.isSecurityDepositReview')
                b-col.py-0.mb-1(cols='4')
                  b-form-group
                    label(label-for='securityDepositReviewPeriodicity' style='font-weight: 600;') Périodicité de la révision
                    validation-provider(#default='{ errors }' name='securityDepositReviewPeriodicity')
                      ejs-dropdownlist(id='securityDepositReviewPeriodicity' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource='periodicitiesList' v-model='leaseForm.securityDepositReviewPeriodicityId')
                        template(v-slot:footerTemplate)
                          div.p-50
                            ejs-button(v-on:click.native="newPeriodicity" :content="'+ Nouvelle périodicité'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
            b-row.my-0
              b-col.py-0(cols='12')
                .d-flex.justify-content-between(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem;')
                  div
                    b-button(v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant='outline-primary' @click='cancel()')
                      feather-icon(icon='ArrowLeftIcon')
                        | Annuler
                    b-button.btn-icon.ml-2(title="Archiver" v-if='leaseForm.id' variant='outline-danger' @click='archiveLeaseLocal(leaseForm.id, leaseForm.label)')
                      feather-icon(icon='ArchiveIcon')
                  b-button(v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant='primary' @click='create')
                    feather-icon.mr-50(icon='SaveIcon' v-if='leaseForm.id')
                    feather-icon.mr-50(icon='PlusIcon' v-else)
                    | {{ leaseForm.id ? 'Modifier' : 'Ajouter' }}
</template>

<script>
var convert = require("xml-js");
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import axios from "axios";
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import {
  referencielTvaTranslate,
  civilityTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";
import { affairStatus } from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { LeaseModel } from "@/types/api-orisis/models/LeaseModels";

export default {
  props: {
    id: {
      default: 0,
    },
  },
  data() {
    return {
      statusList: affairStatus,
      leaseForm: LeaseModel,
      displayGps: false,
      isCreatingLease: false,
      differentOwnerAddress: false,
      tokenEudonet: "",
      searchLandlordContact: "",
      searchTenantContact: "",
      autocompletePropertyData: [],
      autocompleteLandlordData: [],
      autocompleteLandlordContactData: [],
      autocompleteTenantData: [],
      autocompleteTenantContactData: [],
      step: 0.01,
      min: 0,
      max: 1,

      paymentDayList: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28,
      ],
      chargesPackageOptions: [
        { label: "Provisions pour charges", value: false },
        { label: "Forfait de charges", value: true },
      ],
      subscriptReferenceRentReviewOptions: [
        { label: "Indice de référence", value: true },
        { label: "Pourcentage", value: false },
      ],
      reviewSubScriptTypeList: [
        { label: "ICC (Indice du Cout de la Construction)", value: "ICC" },
        { label: "ILC (Indice des Loyers Commerciaux)", value: "ILC" },
        {
          label: "ILAT (Indice des Loyers des Activités Tertiaires)",
          value: "ILAT",
        },
        { label: "IRL (Indice de référence des loyers)", value: "IRL" },
      ],
      rentReviewSubScriptList: [],
      required,
      url,
      email,
      displayMore: false,
      dataOrigine: {},
      leaseType: {},
      tenantList: [],
      landlordList: [],
      optionCivility: [
        {
          label: "M. et Mme",
          value: ECivility.MonsieurMadame,
        },
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },

        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
    };
  },
  watch: {
    "leaseForm.rentReviewSubScriptType": function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.leaseForm.rentReviewSubScript = null;
        if (newVal == "ILC") {
          this.getDataInseeILC();
        } else if (newVal == "ILAT") {
          this.getDataInseeILAT();
        } else if (newVal == "ICC") {
          this.getDataInseeICC();
        } else if (newVal == "IRL") {
          this.getDataInseeIRL();
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "workspaceSelected",
      "institutionSelected",
      "institutionSettingsActive",
      "isLoadingLease",
      "isUpdatingLease",
      "collaboratorsList",
      "contactsList",
      "companiesList",
      "periodicitiesList",
      "propertyTypeList",
      "leaseTypeList",
      "sumLeaseModel",
      "affair",
    ]),
    referencielTvaOptions() {
      return this.institutionSettingsActive.referencielTvas.map((elem) => {
        return { label: elem.referencielTva.label, id: elem.referencielTva.id };
      });
    },
  },
  async created() {
    this.getPeriodicities({});
    this.getLeaseTypes({});
    this.getContacts({});
    this.getCompanies({});
    this.getTokenEudonet();

    await this.getCompanies({
      companyTypeId: 6,
      updateState: false,
    }).then((res) => {
      // A VERIFIER (BAP) - Vérifier le retour de res
      this.landlordList = res;
    });
    await this.getCompanies({
      companyTypeId: 7,
    }).then((res) => {
      // A VERIFIER (BAP) - Vérifier le retour de res
      this.tenantList = res;
    });
  },
  async mounted() {
    window.addEventListener("scroll", this.onScroll, true);
    this.deteleAutocomplete();
    await this.popupLeaseEvent(this.id);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "updateAffair",
      "getPeriodicities",
      "createLease",
      "getLeaseById",
      "updateLease",
      "archiveLease",
      "createAffair",
      "getContacts",
      "createCompany",
      "createContact",
      "leaseGroupFunctions",
      "getLeaseTypes",
    ]),
    formatCurrency,
    referencielTvaTranslate,
    civilityTranslate,
    leaseGroupFunctionsLocal(args) {
      this.leaseGroupFunctions({
        lease: { ...this.leaseForm, periodicityId: args.itemData.id },
      });
    },
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        "#nav-sticky-vertical ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    resetTenant() {
      this.leaseForm.tenantId = null;
      this.leaseForm.tenantName = null;
      this.leaseForm.tenantCivility = null;
      this.leaseForm.tenantFirstName = null;
      this.leaseForm.tenantLastName = null;
      this.leaseForm.tenantAddress = null;
      this.leaseForm.tenantAddressComplement = null;
      this.leaseForm.tenantZipCode = null;
      this.leaseForm.tenantCity = null;
      this.leaseForm.tenantCountry = null;
      this.leaseForm.tenantContactId = null;
      this.leaseForm.tenantContactCivility = null;
      this.leaseForm.tenantContactLastName = null;
      this.leaseForm.tenantContactFirstName = null;
      this.leaseForm.tenantContactPhone = null;
      this.leaseForm.tenantContactEmail = null;
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
      elements = document.querySelectorAll(".not-autocomplete");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    async popupLeaseEvent(id) {
      this.isCreatingLease = false;
      if (id !== "" && id !== 0 && id !== null) {
        await this.getLeaseById({
          leaseId: id,
        }).then(async (res) => {
          this.leaseForm.tenantName = res.tenantName;
          this.leaseForm = res;
          this.dataOrigine = JSON.parse(JSON.stringify(res));
          if (this.leaseForm.rentReviewSubScriptType == "ILC") {
            await this.getDataInseeILC();
          } else if (this.leaseForm.rentReviewSubScriptType == "ILAT") {
            await this.getDataInseeILAT();
          } else if (this.leaseForm.rentReviewSubScriptType == "ICC") {
            await this.getDataInseeICC();
          } else if (this.leaseForm.rentReviewSubScriptType == "IRL") {
            await this.getDataInseeIRL();
          }
          this.dataOrigine = JSON.stringify(this.leaseForm);
        });
      } else {
        this.initializeForm();
      }
      this.leaseGroupFunctions({ lease: this.leaseForm });
    },
    initializeForm() {
      this.leaseForm = {
        ...LeaseModel,
        landlordName: this.institutionSelected.name,
        landlordAddress: this.institutionSelected.address,
        landlordAddressComplement: this.institutionSelected.addressComplement
          ? this.institutionSelected.addressComplement
          : null,
        landlordZipCode: this.institutionSelected.zipCode,
        landlordCity: this.institutionSelected.city,
        landlordCountry: this.institutionSelected.country
          ? this.institutionSelected.country
          : null,
        institutionId: this.institutionSelected.id,
      };
      this.dataOrigine = JSON.stringify(this.leaseForm);
    },
    newPropertyType() {
      this.$router.push({ path: "/billing/property-types" });
    },
    newLeaseType() {
      this.$router.push({ path: "/billing/lease-types" });
    },
    newPeriodicity() {
      this.$router.push({ path: "/billing/periodicities" });
    },
    create(e) {
      e.preventDefault();
      this.$refs.formCreateLease
        .validate()
        .then(async (success1) => {
          this.isCreatingLease = true;
          if (success1) {
            if (this.leaseForm.id) {
              this.$store.commit("SET_IS_UPDATING_LEASE", true);
              // Si il n'existe pas créé le contact locataire
              if (
                (this.leaseForm.tenantContactFirstName ? true : false) &&
                (this.leaseForm.tenantContactLastName ? true : false) &&
                !this.leaseForm.tenantContactId
              ) {
                let tenantContact = {
                  id: 0,
                  civility: this.leaseForm.tenantContactCivility,
                  lastName: this.leaseForm.tenantContactLastName,
                  firstName: this.leaseForm.tenantContactFirstName,
                  phoneNumber: this.leaseForm.tenantContactPhone,
                  secondaryPhoneNumber: null,
                  email: this.leaseForm.tenantContactEmail,
                  activity: null,
                  collaboratorId: null,
                };
                await this.createContact({ contact: tenantContact }).then(
                  async (resTenantContact) => {
                    this.leaseForm.tenantContactId = resTenantContact.id;
                  }
                );
              }

              // Si il n'existe pas créé le locataire dans la base ORISIS
              if (
                (this.leaseForm.tenantName ? true : false) &&
                !this.leaseForm.tenantId
              ) {
                let tenant = {
                  id: 0,
                  addresses: [
                    {
                      address: this.leaseForm.tenantAddress
                        ? this.leaseForm.tenantAddress
                        : null,
                      addressComplement: this.leaseForm.tenantAddressComplement
                        ? this.leaseForm.tenantAddressComplement
                        : null,
                      zipCode: this.leaseForm.tenantZipCode
                        ? this.leaseForm.tenantZipCode
                        : null,
                      city: this.leaseForm.tenantCity
                        ? this.leaseForm.tenantCity
                        : null,
                      country: this.leaseForm.tenantCountry
                        ? this.leaseForm.tenantCountry
                        : null,
                      label: this.leaseForm.tenantName
                        ? this.leaseForm.tenantName
                        : null,
                      isDefault: true,
                      // latitude: this.leaseForm.tenantLatitude
                      //   ? this.leaseForm.tenantLatitude
                      //   : 0,
                      // longitude: this.leaseForm.tenantLongitude
                      //   ? this.leaseForm.tenantLongitude
                      //   : 0,
                      id: 0,
                    },
                  ],
                  contacts: this.leaseForm.tenantContact.tenantContactId
                    ? [
                        {
                          contactId: this.leaseForm.tenantContactId,
                          label: "Contact principal",
                        },
                      ]
                    : [],
                  color: null,
                  email: null,
                  phoneNumber: null,
                  secondaryPhoneNumber: null,
                  tvaNumber: null,
                  isParticular: this.leaseForm.tenantIsParticular,
                  name: this.leaseForm.tenantName
                    ? this.leaseForm.tenantName
                    : null,
                  civility: this.leaseForm.tenantCivility,
                  lastName: this.leaseForm.tenantLastName
                    ? this.leaseForm.tenantLastName
                    : null,
                  firstName: this.leaseForm.tenantFirstName
                    ? this.leaseForm.tenantFirstName
                    : null,
                  notes: null,
                  siret: this.leaseForm.tenantSiret
                    ? this.leaseForm.tenantSiret
                    : null,
                  companyTypeId: 7,
                  companyType: { name: "locataires", id: "7" },
                  apeCode: null,
                  rcsCode: null,
                  source: null,
                  code: null,
                  internalNote: null,
                  annualSales: null,
                  annualSalesYear: null,
                  collaboratorId: null,
                  institutionId: this.institutionSelected.id,
                };
                await this.createCompany({ company: tenant }).then(
                  async (resTenant) => {
                    this.leaseForm.tenantId = resTenant.id;
                  }
                );
              }

              // Si il n'existe pas créé le contact propriétaire
              if (
                (this.leaseForm.landlordContactFirstName ? true : false) &&
                (this.leaseForm.landlordContactLastName ? true : false) &&
                !this.leaseForm.landlordContactId
              ) {
                let landordLordContact = {
                  id: 0,
                  civility: this.leaseForm.landLordContactCivility,
                  lastName: this.leaseForm.landlordContactLastName
                    ? this.leaseForm.landlordContactLastName
                    : null,
                  firstName: this.leaseForm.landlordContactFirstName
                    ? this.leaseForm.landlordContactFirstName
                    : null,
                  phoneNumber: this.leaseForm.landlordContactPhone
                    ? this.leaseForm.landlordContactPhone
                    : null,
                  secondaryPhoneNumber: null,
                  email: this.leaseForm.landlordContactEmail,
                  activity: null,
                  collaboratorId: null,
                };
                await this.createContact({ contact: landordLordContact }).then(
                  async (resLandlord) => {
                    this.leaseForm.landlordContactId = resLandlord.id;
                  }
                );
              }

              // Mise à jour de l'affaire associée au bail
              let affairForm = {
                ...this.affair,
                name: this.leaseForm.label,
                startDate: this.leaseForm.startDate
                  ? this.leaseForm.startDate
                  : null,
                endDate: this.leaseForm.endDate ? this.leaseForm.endDate : null,
                code: this.leaseForm.codeCRO ? this.leaseForm.codeCRO : null,
                address: this.leaseForm.propertyAddress
                  ? this.leaseForm.propertyAddress
                  : null,
                addressComplement: this.leaseForm.propertyAddressComplement
                  ? this.leaseForm.propertyAddressComplement
                  : null,
                zipCode: this.leaseForm.propertyZipCode
                  ? this.leaseForm.propertyZipCode
                  : null,
                city: this.leaseForm.propertyCity
                  ? this.leaseForm.propertyCity
                  : null,
                country: this.leaseForm.propertyCountry
                  ? this.leaseForm.propertyCountry
                  : null,
                ownAddressAffair: false,
                companyId: this.leaseForm.tenantId
                  ? this.leaseForm.tenantId
                  : null,
                latitude: this.leaseForm.propertyLatitude
                  ? this.leaseForm.propertyLatitude
                  : 0,
                longitude: this.leaseForm.propertyLongitude
                  ? this.leaseForm.propertyLongitude
                  : 0,
                affairTypeId: 1,
                status: this.leaseForm.status,
              };
              await this.updateAffair({
                affair: affairForm,
                route: "affair",
              }).then(async (res) => {
                this.leaseForm.affairId = res.id;
                await this.updateLease({
                  lease: { ...this.leaseForm, propertyName: null },
                  updateState: false,
                });
              });
            } else {
              this.$store.commit("SET_IS_CREATING_LEASE", true);
              // Si il n'existe pas créé le contact locataire
              if (
                (this.leaseForm.tenantContactFirstName ? true : false) &&
                (this.leaseForm.tenantContactLastName ? true : false) &&
                !this.leaseForm.tenantContactId
              ) {
                let tenantContact = {
                  id: 0,
                  civility: this.leaseForm.tenantContactCivility,
                  lastName: this.leaseForm.tenantContactLastName,
                  firstName: this.leaseForm.tenantContactFirstName,
                  phoneNumber: this.leaseForm.tenantContactPhone,
                  secondaryPhoneNumber: null,
                  email: this.leaseForm.tenantContactEmail,
                  activity: null,
                  collaboratorId: null,
                };
                await this.createContact({ contact: tenantContact }).then(
                  async (resTenantContact) => {
                    this.leaseForm.tenantContactId = resTenantContact.id;
                  }
                );
              }

              // Si il n'existe pas créé le locataire dans la base ORISIS
              if (
                (this.leaseForm.tenantName ? true : false) &&
                !this.leaseForm.tenantId
              ) {
                let tenant = {
                  id: 0,
                  addresses: [
                    {
                      address: this.leaseForm.tenantAddress
                        ? this.leaseForm.tenantAddress
                        : null,
                      addressComplement: this.leaseForm.tenantAddressComplement
                        ? this.leaseForm.tenantAddressComplement
                        : null,
                      zipCode: this.leaseForm.tenantZipCode
                        ? this.leaseForm.tenantZipCode
                        : null,
                      city: this.leaseForm.tenantCity
                        ? this.leaseForm.tenantCity
                        : null,
                      country: this.leaseForm.tenantCountry
                        ? this.leaseForm.tenantCountry
                        : null,
                      label: this.leaseForm.tenantName
                        ? this.leaseForm.tenantName
                        : null,
                      isDefault: true,
                      // latitude: this.leaseForm.tenantLatitude
                      //   ? this.leaseForm.tenantLatitude
                      //   : 0,
                      // longitude: this.leaseForm.tenantLongitude
                      //   ? this.leaseForm.tenantLongitude
                      //   : 0,
                      id: 0,
                    },
                  ],
                  contacts: this.leaseForm.tenantContactId
                    ? [
                        {
                          contactId: this.leaseForm.tenantContactId,
                          label: "Contact principal",
                        },
                      ]
                    : null,
                  color: null,
                  email: null,
                  phoneNumber: null,
                  secondaryPhoneNumber: null,
                  tvaNumber: null,
                  isParticular: this.leaseForm.tenantIsParticular,
                  name: this.leaseForm.tenantName
                    ? this.leaseForm.tenantName
                    : null,
                  civility: this.leaseForm.tenantCivility,
                  lastName: this.leaseForm.tenantLastName
                    ? this.leaseForm.tenantLastName
                    : null,
                  firstName: this.leaseForm.tenantFirstName
                    ? this.leaseForm.tenantFirstName
                    : null,
                  notes: null,
                  siret: this.leaseForm.tenantSiret
                    ? this.leaseForm.tenantSiret
                    : null,
                  companyTypeId: 7,
                  companyType: { name: "locataires", id: "7" },
                  apeCode: null,
                  rcsCode: null,
                  source: null,
                  code: null,
                  internalNote: null,
                  annualSales: null,
                  annualSalesYear: null,
                  collaboratorId: null,
                  institutionId: this.institutionSelected.id,
                };
                await this.createCompany({ company: tenant }).then(
                  async (resTenant) => {
                    this.leaseForm.tenantId = resTenant.id;
                  }
                );
              } else if (this.leaseForm.tenantId) {
                // MISE A JOUR DE LA LIAISON DU CONTACT LOCATAIRE AVEC LE LOCATAIRE
              }

              // Si il n'existe pas créé le contact propriétaire
              if (
                (this.leaseForm.landlordContactFirstName ? true : false) &&
                (this.leaseForm.landlordContactLastName ? true : false) &&
                !this.leaseForm.landlordContactId
              ) {
                let landordLordContact = {
                  id: 0,
                  civility: this.leaseForm.landLordContactCivility,
                  lastName: this.leaseForm.landlordContactLastName
                    ? this.leaseForm.landlordContactLastName
                    : null,
                  firstName: this.leaseForm.landlordContactFirstName
                    ? this.leaseForm.landlordContactFirstName
                    : null,
                  phoneNumber: this.leaseForm.landlordContactPhone
                    ? this.leaseForm.landlordContactPhone
                    : null,
                  secondaryPhoneNumber: null,
                  email: this.leaseForm.landlordContactEmail
                    ? this.leaseForm.landlordContactEmail
                    : null,
                  activity: null,
                  collaboratorId: null,
                };
                await this.createContact({ contact: landordLordContact }).then(
                  async (resLandlord) => {
                    this.leaseForm.landlordContactId = resLandlord.id;
                  }
                );
              }

              // Crétion de l'affaire associée au bail
              let affairForm = {
                id: 0,
                name: this.leaseForm.label,
                description: null,
                startDate: this.leaseForm.startDate
                  ? this.leaseForm.startDate
                  : null,
                endDate: this.leaseForm.endDate ? this.leaseForm.endDate : null,
                collaboratorIds: [],
                code: this.leaseForm.codeCRO ? this.leaseForm.codeCRO : null,
                address: this.leaseForm.propertyAddress
                  ? this.leaseForm.propertyAddress
                  : null,
                addressComplement: this.leaseForm.propertyAddressComplement
                  ? this.leaseForm.propertyAddressComplement
                  : null,
                zipCode: this.leaseForm.propertyZipCode
                  ? this.leaseForm.propertyZipCode
                  : null,
                city: this.leaseForm.propertyCity
                  ? this.leaseForm.propertyCity
                  : null,
                country: this.leaseForm.propertyCountry
                  ? this.leaseForm.propertyCountry
                  : null,
                ownAddressAffair: false,
                companyId: this.leaseForm.tenantId
                  ? this.leaseForm.tenantId
                  : null,
                latitude: this.leaseForm.propertyLatitude
                  ? this.leaseForm.propertyLatitude
                  : 0,
                longitude: this.leaseForm.propertyLongitude
                  ? this.leaseForm.propertyLongitude
                  : 0,
                affairTypeId: 1,
                status: this.leaseForm.status,
              };
              await this.createAffair({
                affair: affairForm,
                route: "affair",
              }).then(async (res) => {
                this.leaseForm.affairId = res.id;

                await this.createLease({ lease: this.leaseForm });
              });
            }
            this.$nextTick(() => {
              this.isCreatingLease = false;
              this.$tabs.close({ to: "/leases" });
            });
          } else {
            this.isCreatingLease = false;
            this.$bvToast.toast("Veuillez remplir les champs obligatoires", {
              title: "Erreur",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((err) => {
          this.$bvToast.toast("Veuillez remplir les champs obligatoires", {
            title: "Erreur",
            variant: "danger",
            solid: true,
          });
          console.error(err);
        });
    },
    getTokenEudonet() {
      let url = `https://dev.lhotellier.orisis.fr/api-eudonet.php?request=authenticate`;
      axios
        .get(url)
        .then((res) => {
          this.tokenEudonet = encodeURI(res.data);
        })
        .catch((err) => {
          console.error("tokenEudonet error", err);
        });
    },
    // Data From INSEE
    async getDataInseeILC() {
      let _this = this;
      let urlILC = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001532540`;
      await axios
        .get(urlILC, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_INSEE_KEY}`,
          },
        })
        .then((res) => {
          var result = convert.xml2json(res.data, {
            ignoreComment: true,
            alwaysChildren: true,
          });
          _this.rentReviewSubScriptList = JSON.parse(
            result
          ).elements[0].elements[1].elements[0].elements.map((elem) => {
            return {
              label:
                elem.attributes.TIME_PERIOD + " - " + elem.attributes.OBS_VALUE,
              value: elem.attributes.OBS_VALUE,
            };
          });
        })
        .catch((err) => {
          console.error("insee error", err);
        });
    },
    async getDataInseeILAT() {
      let _this = this;
      let urlILAT = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001617112`;
      await axios
        .get(urlILAT, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_INSEE_KEY}`,
          },
        })
        .then((res) => {
          var result = convert.xml2json(res.data, {
            ignoreComment: true,
            alwaysChildren: true,
          });
          _this.rentReviewSubScriptList = JSON.parse(
            result
          ).elements[0].elements[1].elements[0].elements.map((elem) => {
            return {
              label:
                elem.attributes.TIME_PERIOD + " - " + elem.attributes.OBS_VALUE,
              value: elem.attributes.OBS_VALUE,
            };
          });
        })
        .catch((err) => {
          console.error("insee error", err);
        });
    },
    async getDataInseeICC() {
      let _this = this;
      let urlICC = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/000008630`;
      await axios
        .get(urlICC, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_INSEE_KEY}`,
          },
        })
        .then((res) => {
          var result = convert.xml2json(res.data, {
            ignoreComment: true,
            alwaysChildren: true,
          });
          _this.rentReviewSubScriptList = JSON.parse(
            result
          ).elements[0].elements[1].elements[0].elements.map((elem) => {
            return {
              label:
                elem.attributes.TIME_PERIOD + " - " + elem.attributes.OBS_VALUE,
              value: elem.attributes.OBS_VALUE,
            };
          });
        })
        .catch((err) => {
          console.error("insee error", err);
        });
    },
    async getDataInseeIRL() {
      let _this = this;
      let urlIRL = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001515333`;
      await axios
        .get(urlIRL, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_INSEE_KEY}`,
          },
        })
        .then((res) => {
          var result = convert.xml2json(res.data, {
            ignoreComment: true,
            alwaysChildren: true,
          });
          _this.rentReviewSubScriptList = JSON.parse(
            result
          ).elements[0].elements[1].elements[0].elements.map((elem) => {
            return {
              label:
                elem.attributes.TIME_PERIOD + " - " + elem.attributes.OBS_VALUE,
              value: elem.attributes.OBS_VALUE,
            };
          });
        })
        .catch((err) => {
          console.error("insee error", err);
        });
    },
    autocompleteLandlordContact: debounce(async function (indexSource) {
      let data = [];
      let dataOutput = [];

      /* ICI SPECIAL LHOTELLIER */
      if (this.workspaceSelected.id == 14) {
        // Fetch Eudonet
        let dataEudonet = [];
        let paramsEudonet = {
          request: "searchContact",
          q: this.searchLandlordContact,
          token: this.tokenEudonet,
        };
        await axios
          .post(
            "https://dev.lhotellier.orisis.fr/api-eudonet.php",
            paramsEudonet,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((res) => {
            dataEudonet = Array.isArray(res.data)
              ? res.data.reduce((acc, current) => {
                  const x = acc.find(
                    (item) =>
                      item.civility === current.civility &&
                      item.email === current.email &&
                      item.lastName === current.lastName &&
                      item.phoneNumber === current.phoneNumber &&
                      item.secondaryPhoneNumber === current.secondaryPhoneNumber
                  );
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, [])
              : [];
          })
          .catch((err) => {
            console.error("autocompleteLandlord error", err);
          });
        data = dataEudonet;
      }

      dataOutput = this.contactsList.filter(
        (el) =>
          el.lastName
            .toLowerCase()
            .includes(this.searchLandlordContact.toLowerCase()) ||
          el.firstName
            .toLowerCase()
            .includes(this.searchLandlordContact.toLowerCase())
      );
      data.forEach((current) => {
        if (
          !dataOutput.find(
            (item) =>
              item.email === current.email &&
              item.lastName === current.lastName &&
              item.phoneNumber === current.phoneNumber
          )
        ) {
          dataOutput.push(current);
        }
      });
      this.autocompleteLandlordContactData = dataOutput;
    }, 200),
    autocompleteLandlordContactSelected(item) {
      this.leaseForm.landlordContactCivility = item.civility
        ? isNaN(item.civility)
          ? this.translateEudonetCivility(item.civility)
          : item.civility
        : 0;
      this.leaseForm.landlordContactLastName = item.lastName;
      this.leaseForm.landlordContactFirstName = item.firstName;
      this.leaseForm.landlordContactPhone = item.phoneNumber;
      this.leaseForm.landlordContactEmail = item.email;
      this.leaseForm.landlordContactId = item.id;
    },
    // Autocomplete Tenant from eudonet
    autocompleteTenant: debounce(async function (indexSource) {
      let data = [];
      let dataOutput = [];
      let dataOutputOrigine = [];
      let dataOutputFixed = [];
      let dataGouv = [];
      if (this.leaseForm.tenantName.trim().length > 2) {
        // Fetch Data.Gouv
        if (!this.leaseForm.tenantIsParticular) {
          await fetch(
            `https://recherche-entreprises.api.gouv.fr/search?q=${this.leaseForm.tenantName}&page=1&per_page=25`
          )
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              dataGouv = data.results.map((elem) => {
                let dataFormat = {
                  name: elem.nom_complet,
                  addresses: [
                    {
                      address: elem.siege.numero_voie
                        ? elem.siege.numero_voie + " " + elem.siege.libelle_voie
                        : elem.siege.libelle_voie,
                      addressComplement: elem.siege.complement_adresse
                        ? elem.siege.complement_adresse
                        : null,
                      zipCode: elem.siege.code_postal,
                      city: elem.siege.libelle_commune,
                      country: "France",
                    },
                  ],
                  siret: elem.siege.siret,
                  id: 0,
                  source: "gouv",
                };
                return dataFormat;
              });
            });
          data = dataGouv;

          /* ICI SPECIAL LHOTELLIER */
          if (this.workspaceSelected.id == 14) {
            // Fetch Eudonet
            let dataEudonet = [];
            let paramsEudonet = {
              request: "searchCompany",
              q: this.leaseForm.tenantName,
              token: this.tokenEudonet,
            };
            await axios
              .post(
                "https://dev.lhotellier.orisis.fr/api-eudonet.php",
                paramsEudonet,
                { headers: { "Content-Type": "application/json" } }
              )
              .then((res) => {
                dataEudonet = res.data;
              })
              .catch((err) => {
                console.error("autocompleteTenant error", err);
              });
            data = dataGouv.concat(dataEudonet);
          }
        }
        dataOutputOrigine = this.tenantList.filter((el) => {
          return (
            el.name
              .toLowerCase()
              .includes(this.leaseForm.tenantName.toLowerCase()) &&
            el.isParticular == this.leaseForm.tenantIsParticular
          );
        });
      }
      dataOutput = dataOutputFixed = structuredClone(dataOutputOrigine);
      data.forEach((current) => {
        if (!dataOutputFixed.find((item) => item.name == current.name)) {
          dataOutput.push(current);
        }
      });
      this.autocompleteTenantData = dataOutput;
    }, 200),
    autocompleteTenantSelected(item) {
      this.leaseForm.tenantName = item.name;
      this.leaseForm.tenantLastName = item.lastName;
      this.leaseForm.tenantFirstName = item.firstName;
      this.leaseForm.tenantCivility = item.civility ? item.civility : 0;
      this.leaseForm.tenantAddress =
        item.addresses.length > 0 ? item.addresses[0].address : null;
      this.leaseForm.tenantAddressComplement =
        item.addresses.length > 0 ? item.addresses[0].addressComplement : null;
      this.leaseForm.tenantZipCode =
        item.addresses.length > 0 ? item.addresses[0].zipCode : null;
      this.leaseForm.tenantCity =
        item.addresses.length > 0 ? item.addresses[0].city : null;
      this.leaseForm.tenantCountry = "France";
      this.leaseForm.tenantSiret = item.siret;
      this.leaseForm.tenantId = item.id;
    },
    autocompleteTenantContact: debounce(async function (indexSource) {
      let data = [];
      let dataOutput = [];

      /* ICI SPECIAL LHOTELLIER */
      if (this.workspaceSelected.id == 14) {
        // Fetch Eudonet
        let dataEudonet = [];
        let paramsEudonet = {
          request: "searchContact",
          q: this.searchTenantContact,
          token: this.tokenEudonet,
        };
        await axios
          .post(
            "https://dev.lhotellier.orisis.fr/api-eudonet.php",
            paramsEudonet,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((res) => {
            dataEudonet = Array.isArray(res.data)
              ? res.data.reduce((acc, current) => {
                  const x = acc.find(
                    (item) =>
                      item.civility === current.civility &&
                      item.email === current.email &&
                      item.lastName === current.lastName &&
                      item.phoneNumber === current.phoneNumber &&
                      item.secondaryPhoneNumber === current.secondaryPhoneNumber
                  );
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, [])
              : [];
          })
          .catch((err) => {
            console.error("autocompleteLandlord error", err);
          });
        data = dataEudonet;
      }
      dataOutput = this.contactsList.filter(
        (el) =>
          el.lastName
            .toLowerCase()
            .includes(this.searchTenantContact.toLowerCase()) ||
          el.firstName
            .toLowerCase()
            .includes(this.searchTenantContact.toLowerCase())
      );
      data.forEach((current) => {
        if (
          !dataOutput.find(
            (item) =>
              item.email === current.email &&
              item.lastName === current.lastName &&
              item.phoneNumber === current.phoneNumber
          )
        ) {
          dataOutput.push(current);
        }
      });
      this.autocompleteTenantContactData = dataOutput;
    }, 200),
    autocompleteTenantContactSelected(item) {
      this.leaseForm.tenantContactCivility = item.civility
        ? isNaN(item.civility)
          ? this.translateEudonetCivility(item.civility)
          : item.civility
        : 0;
      this.leaseForm.tenantContactLastName = item.lastName;
      this.leaseForm.tenantContactFirstName = item.firstName;
      this.leaseForm.tenantContactPhone = item.phoneNumber;
      this.leaseForm.tenantContactEmail = item.email;
      this.leaseForm.tenantContactId = item.id;
    },
    translateEudonetCivility(civility) {
      switch (civility) {
        case "Monsieur":
          return 0;
          break;

        case "Madame":
          return 1;
          break;

        case "Mademoiselle":
          return 1;
          break;

        default:
          return 2;
          break;
      }
    },
    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function (indexSource) {
      if (this.leaseForm.propertyAddress.trim().length > 2) {
        fetch(
          `https://api-adresse.data.gouv.fr/search/?q=${this.leaseForm.propertyAddress}&autocomplete=0&limit=25`
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.autocompletePropertyData = data.features;
          });
      }
    }, 200),
    autocompleteAddressSelected(item) {
      this.leaseForm.propertyAddress = item.properties.name;
      this.leaseForm.propertyCity = item.properties.city;
      this.leaseForm.propertyZipCode = item.properties.postcode;
      this.leaseForm.propertyCountry = "France";
      this.leaseForm.propertyLatitude = item.geometry.coordinates[1];
      this.leaseForm.propertyLongitude = item.geometry.coordinates[0];
    },
    isInt(n) {
      return Number(n) === n && n % 1 === 0;
    },
    archiveLeaseLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveLease(id);
            this.$tabs.close();
          }
        });
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.leaseForm)) {
        this.$tabs.close();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$tabs.close();
            }
          });
      }
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
  },
  directives: {
    Ripple,
  },
};
</script>
